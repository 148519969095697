* {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
}

html {
  overflow: hidden;
  font-size: 62.5%;
}
body {
  font-size: 1.4rem;
  color: #375563;
  min-width: 1024px;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.Toastify__toast-container {
  min-width: 46rem;
}

.toast-success {
  padding: 5px !important;
  padding-left: 0 !important;
  background-color: #27ae60 !important;
  border-radius: 7px !important;
}

.toast-success-copy {
  padding: 5px !important;
  padding-left: 0 !important;
  background-color: #27ae60 !important;
  border-radius: 7px !important;
  font-size: 14px !important;
}

.toast-success-copy span {
  font-size: 14px !important;
}

.toast-failure {
  padding: 5px !important;
  padding-left: 0 !important;
  background-color: #ff574c !important;
  border-radius: 7px !important;
}

.toast-info {
  padding: 5px !important;
  padding-left: 0 !important;
  background-color: #3db7e6 !important;
  border-radius: 7px !important;
}

.react-datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 94px;
}

.infinite-scroll-component__outerdiv {
  height: calc(100vh - 200px);
  position: relative;
  margin-right: -8px;
}

::-webkit-scrollbar {
  width: 20px;
  height: 3px;
}

::-webkit-scrollbar-track {
  background-color: #e6e6e6;
  opacity: 0.5;
  border-left: 9px solid #ffffff;
  border-right: 9px solid #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #17a9e1;
  border-left: 8px solid #ffffff;
  border-right: 8px solid #ffffff;
  transition: all 0.2s;
}

::-webkit-scrollbar-thumb:hover {
  border-left: 7px solid #ffffff;
  border-right: 7px solid #ffffff;
  transition: all 0.2s;
}
